@import 'utils/variable.less';

input {

  &:-webkit-autofill::first-line,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-family: 'Noto Sans', sans-serif;
    font-variant: tabular-nums;
    font-size: 16px;
  }

  border-style: solid;
}

/* LAYOUT */
.ant-layout {
  background: @layout-bg-color !important;

  &.site-layout {
    background-color: #f2f4f8 !important;
    // overflow: auto;
    // padding-bottom: 12px;
    width: 80vw;
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0 !important;
}

.ant-layout-content {
  margin: 0px 0px 0px 0px;
  min-height: calc(100vh - 96px) !important;
  position: relative;
  background: transparent !important;
  padding: 0 0px;
  overflow: auto;
}

/* AVATAR */
.ant-upload-picture-card-wrapper.circle {
  .ant-upload-select.ant-upload-select-picture-card {
    border-radius: 50%;
    padding: 10px;

    .avatar-picture {
      border-radius: 50%;
      width: 140%;
      height: 140%;
      object-fit: cover;
    }
  }
}

.avatar-uploader {
  height: 100% !important;
  align-items: center !important;
}

/* BUTTON */
.ant-btn-round {
  height: 40px;
  border-radius: 16px;
}

//button title
.ant-btn>span {
  font-weight: 700;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #000000 !important;
}

.ant-btn:disabled {
  color: #CCCCCC !important;
  background-color: unset !important;
}

.button-primary {
  border-radius: 8px !important;
  background-color: #75fa9b !important;
  margin: 2px;
}

.button-secondary {
  border-radius: 8px !important;
  background-color: #f2f4f8 !important;
  margin: 2px;
}

.button-get-template {
  border-radius: 8px !important;
  background-color: #fec10c1a !important;
}

.button-action-table {
  background-color: transparent !important;
  border: none !important;
}

.ant-btn {
  &.ant-btn-primary {
    box-shadow: none;
    text-shadow: none;
    color: #fff;
    border: none;
    border-radius: unset !important;
    background: linear-gradient(266.04deg, #9a1e85 42.38%, #f99d0f 109.66%, #ffcb4f 139.1%);
    font-family: 'Titillium Web';

    &:hover,
    &:active {
      opacity: 0.8;
      color: #fff !important;
      background: linear-gradient(266.04deg, #9A1E85 42.38%, #F99D0F 109.66%, #FFCB4F 139.1%) !important;
    }
  }

  &.ant-btn-round {
    border-radius: 11px;
  }

  &.ant-btn-lg.btn-icon-customize {
    padding-left: 6.4px;

    .btn-icon {
      background: white;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 12px;

      >.anticon {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  &.success,
  &.success:hover {
    border: 1px solid rgb(137, 191, 4);
    color: rgb(137, 191, 4);
    background: white;
  }

  &.success:hover {
    opacity: 0.9;
  }

  &.success[disabled],
  &.success[disabled]:active,
  &.success[disabled]:focus,
  &.success[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
}

.text-error {
  color: red;
}

.content-right {
  display: flex;
  flex-direction: row-reverse;
}

.btn-primary-outlined {
  border: 1px solid @border-primary-color !important;
  box-sizing: border-box;
  border-radius: 1000px;
}

.btn-icon-right {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;

  .anticon {
    margin-left: 10px;
  }
}

.custom-buttom {
  border-radius: 4px !important;
  color: #000 !important;
}

.button-back-drawer {
  background-color: #f3f5f6 !important;
  border-radius: 8px !important;
  border: none !important;
}

.button-test {
  background-color: #75fa9b !important;
  border-radius: 5px !important;
}

.custom-buttom-cancle {
  background-color: #dee3ed !important;
  border-radius: 8px !important;
  margin: 2px;
}

.custom-buttom-drawe {
  background-color: #000000 !important;
  border-radius: 5px !important;
  padding: 5px 25px !important;
  color: #ffffff !important;
}

.custom-buttom-drawe:hover {
  background-color: #000000 !important;
  border-radius: 5px !important;
  padding: 5px 25px !important;
  color: #ffffff !important;
}

.custom-buttom-oke {
  background-color: #75fa9b !important;
  border-radius: 5px !important;
  padding: 5px 25px !important;
  color: #000000 !important;
}

.item-header-drawer {
  font-weight: 500;
  font-size: 14px;
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
}

/* COMMENT */
.wrap-comments {
  .ant-comment {
    .ant-comment-inner {
      padding: 0;
      padding-bottom: 10px;
    }
  }
}

/* AVATAR */
.ant-upload-picture-card-wrapper {
  display: flex !important;
}

/* LIST */
.ant-list {
  color: inherit;
}

/* BUTTON */
.ant-btn-icon-only.ant-btn-sm>* {
  font-size: 11px !important;
  vertical-align: baseline;
}

// SPIN
// .ant-spin-container {
//   // width: 99%;
// }
/* TABLE */
.custom-ant-table {
  border-radius: 2px;

  .ant-table {
    color: @text-color;
    box-shadow: 0 2px 8px rgba(110, 186, 196, 0.16);
    border-radius: 2px;
  }

  // .ant-table-thead {
  //   th {
  //     // text-transform: capitalize;
  //   }
  // }
  .ant-table-tbody {
    tr {
      background-color: #fafafc;

      .ant-table-cell-fix-left,
      .ant-table-cell-fix-right {
        background-color: #fafafc !important;
      }

      td {
        background-color: transparent !important;
        border-bottom: 1px solid white;
      }

      .table-cell-profile {
        display: flex;

        .info {
          line-height: initial;
          max-width: calc(100% - 35px);

          .phone,
          .email {
            font-size: 12px;
          }
        }
      }
    }

    tr:nth-child(2n + 1) {
      background-color: #fbfbfb;
    }
  }

  .ant-table-content {
    border-radius: 2px;
  }

  .ant-table-header,
  .ant-table-content {
    th.ant-table-cell {
      color: @text-color;
      background: @layout-bg-white;
    }
  }

  .ant-table-container {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    border-radius: 16px;

    table>thead>tr>th {
      padding: 16px 8px !important;
    }

    table>thead>tr:first-child th:first-child,
    .ant-table-tbody tr td:first-child {
      &:not(.ant-table-selection-column) {
        padding-left: 12px !important;
      }
    }

    table>thead>tr:first-child th:first-child {
      border-top-left-radius: 20px;
    }

    table>tbody>tr:last-child th:first-child {
      border-bottom-left-radius: 20px;
    }

    table>thead>tr:first-child th:last-child,
    .ant-table-tbody tr td:last-child {
      padding-right: 8px !important;
    }

    table>thead>tr:first-child th:last-child {
      border-top-right-radius: 20px;
    }

    table>tbody>tr:last-child th:last-child {
      border-top-right-radius: 20px;
    }
  }

  .ant-table-pagination.ant-pagination {
    margin: 15px 6px 8px;
  }
}

.custom-comm-table {
  .ant-table.ant-table-middle tfoot>tr>td {
    padding: 5px 5px;
  }
}

.expand-table {
  display: flex;

  .ant-table {
    margin: 0 !important;
  }

  .ant-table-thead>tr>th {
    background: transparent !important;
  }
}

.ant-table.ant-table-middle .ant-table-thead>tr>th {
  padding: 12px 5px !important;
  text-overflow: ellipsis;
  font-weight: 600;
  background: #f2f4f8;
  border-right: 1px solid #dee3ed !important;
}

.ant-table.ant-table-middle .ant-table-tbody>tr:not(.ant-table-measure-row)>td {
  //TODO:table Antd, Chinh boder padding table
  // padding: 12px 5px !important;
  // border: none !important;
}

.comm-table {
  background-color: transparent;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}

.deposit-table {
  border: 1px solid #e8e7e7;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}

.customdrawer-table {
  .ant-table-thead {
    .ant-table-cell {
      background-color: rgba(212, 218, 224, 0.37) !important;
    }

    .ant-table-row-selected {
      background: #ffffff18 !important;
    }
  }

  .ant-table-container table>thead>tr:first-child th:first-child {
    pointer-events: none;
  }
}

.table-font-size-12 {
  .ant-table-cell {
    font-size: 12px !important;
    padding-top: 12px;
  }
}

.header-table-cusstom {
  background-color: #dee3ed;
  padding: 3px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #d6d6d685;
}

/* PAGINATION */
.ant-pagination {
  display: flex;

  .ant-pagination-total-text {
    margin-right: auto;
  }

  .ant-pagination-item {
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0 4px !important;

    &:not(.ant-pagination-item-active) {
      border: 1px solid #cccccc;
    }

    &.ant-pagination-item-active {
      border: 1px solid #FFCB4F;
    }

    &.ant-pagination-item-active a {
      color: #FFCB4F;
    }
  }
}

/* CARD */
.ant-card {
  box-shadow: 0 2px 8px rgba(@primary-color, 0.06);

  .ant-card-body {
    padding: 3px 4px;
    width: 100%;
    // padding: 0px;
  }

  .ant-card-cover {
    margin-right: 0;
    margin-left: 0;
  }
}

.ant-card-border-25 {
  border-radius: 25px !important;
}

.card-container p {
  margin: 0;
}

.ant-tabs-content {
  height: 100%;
}

.card-container>.ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}

.card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}

.card-container>.ant-tabs-card>.ant-tabs-nav::before {
  display: none;
}

.card-container>.ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

.card-container>.ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}

#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}

[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-content {
  margin-top: -8px;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
  background: #141414;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}

/* MENU */
.ant-menu {
  color: @text-color !important;
}

.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-submenu:hover,
.ant-menu>.ant-menu-item-active,
.ant-menu>.ant-menu-submenu-active,
.ant-menu>.ant-menu-item-open,
.ant-menu>.ant-menu-submenu-open,
.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected {
  border-bottom: 0px;
  // background-color: @primary-color;
}

.ant-menu-item-selected {
  background-color: #f2f4f8 !important;
}

.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: #f2f4f8 !important;
  border-bottom: 0px !important;
}

.ant-menu>.ant-menu-item {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  margin: 10px 5px 0px 5px !important;
  line-height: 35px;
}

.ant-menu-horizontal>.ant-menu-item a:hover {
  color: #000000 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 2px solid #f2f4f8 !important;
}

.ant-menu-horizontal>.ant-menu-item-selected a {
  color: black !important;
  font-weight: bold;
}

.ant-menu-bar {
  border-radius: 8px;
}

.select-option-hightlight {
  background-color: #eed1fa93;
}

.select-option-hightlight:hover {
  background-color: #b996c793 !important;
}

.form-item-highlight {
  label {
    font-weight: 800 !important;
  }

  .ant-input-number-input {
    background-color: #e6f9ee;
    border: #1e854a solid 1px;
    border-radius: 6px;
  }
}

.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.form-item-highlight-2 {
  label {
    font-weight: 800 !important;
  }

  .ant-input-number-input {
    background-color: #e3eefd;
    border: #2f80ed solid 1px;
    border-radius: 6px;
  }
}

.form-item-highlight-red {
  label {
    font-weight: 800 !important;
  }

  .ant-input-number-input {
    background-color: #fde5e3;
    border: #da473c solid 1px;
    border-radius: 6px;
  }
}

.warning-form {
  display: flex !important;
  align-items: center;
  color: red !important;
}

/* FORM SIZE*/
// .ant-select-lg,
// .ant-input-lg,
// .ant-input-number-lg,
// .ant-input-affix-wrapper-lg,
// .ant-picker-large .ant-picker-input > input {
// }
// .ant-form-large .ant-form-item-control-input {
//   min-height: 44px !important;
// }
.ant-input-lg {
  padding: 12px 12px !important;
}

// .ant-input-affix-wrapper-lg {
//   input {
//     padding-left: 3px !important;
//   }
// }
///// Because reduce font-size to 14 then must reduce height to 37
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) {
  .ant-select-selector {
    height: 44px !important;

    .ant-select-selection-item {
      line-height: 42px !important;
      height: 42px !important;
    }

    .ant-select-selection-search,
    .ant-select-selection-search-input {
      height: 42px !important;
    }
  }
}

.ant-select-multiple.ant-select-lg.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-lg.ant-select-allow-clear .ant-select-selector {
  min-height: 44px !important;
}

//.ant-input-number-lg {
//  height: 44px !important;
//  input {
//    height: 42px !important;
//  }
//}

/* INPUT */
.ant-col {
  width: 100% !important;

  label {
    display: inline-block;
    // overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    width: 100% !important;
    font-size: 14px !important;
    color: #333333 !important;
  }
}

.ant-checkbox-wrapper {
  display: inline-flex !important;
  // justify-content: center;
}

.ant-col-xl-24 .ant-form-item-label {
  padding-bottom: 0 !important;
}

.ant-form-vertical .ant-form-item {
  margin-bottom: 5px;
  position: relative;

  .ant-form-item-label {
    padding: 0 !important;
    height: 20px;
    line-height: 20px;

    label {
      font-size: 12px;
      height: 12px;
      background: rgba(255, 255, 255, 0);
      margin: 0 4px;
      font-weight: 400;
      padding-right: 5px;
    }
  }
}

.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.ant-input-group>.ant-input:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ant-picker {
  border-radius: 16px;
}

.noti-page {
  padding: 16px;
}

.ant-select-selector {
  border-color: @border-color !important;
}

.ant-checkbox-group {
  margin-top: 10px;
}

.ant-checkbox-inner {
  border: 1px #333 solid !important;
  // border-radius: 5px !important;
}

.ant-checkbox-checked::after {
  border: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #F99D0F !important;
  background-color: #F99D0F !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #000 !important;
}

.checkbox-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 4px;
  justify-content: space-between;
  border: 1px solid #f2f4f8;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.ant-select-selector,
.ant-picker,
.ant-input-affix-wrapper,
.ant-input,
.ant-select,
.ant-input-number {
  border-radius: 5px !important;
  border-color: @border-color !important;
  // height: 50px;
}

.ant-select-multiple.ant-select-lg .ant-select-selector {
  border-radius: 5px !important;
}

.ant-input {
  background-color: #f9f9f9 !important;
  // border: 1px solid #dddddd !important;
}

.ant-input:focus {
  background-color: #fff !important;
}

.ant-input[type='password'] {
  border: none !important;
  -webkit-box-shadow: 0 0 0 30px #f9f9f9 inset !important;
  font-size: 14px !important;
}

.ant-input-affix-wrapper {
  padding: 0px 12px !important;
  background-color: #f9f9f9 !important;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  background-color: #fff !important;
}

.ant-input-affix-wrapper>.ant-input:focus {
  box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.ant-input-password {
  padding: 0px 12px 0px 0px !important;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child),
.ant-input-group .ant-input:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ant-input-group .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  // background-color: transparent;
  border-color: @border-color;
}

.ant-input-group-addon:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background-color: transparent;
  border-color: @border-color;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon {
  background-color: transparent;

  &:last-child .ant-input-search-button {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    background-color: transparent !important;
    border-color: @border-color;
  }
}

.ant-input-group {
  .ant-input-group-addon {
    .ant-select-selector {
      border-radius: 0 !important;
      border: 0 !important;
      background-color: transparent;
    }
  }
}

.ant-input[disabled] {
  color: rgb(0 0 0) !important;
}

.ant-picker-input>input[disabled] {
  color: rgb(0 0 0) !important;
}

.ant-input-number-disabled {
  color: rgb(0 0 0) !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgb(0 0 0) !important;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: rgb(0 0 0) !important;
}

.ant-select-selection-placeholder {
  color: #999696 !important;
}

.ant-select-disabled {
  .ant-select-selection-placeholder {
    color: #c8c8c8 !important;
  }
}

.width-max-content {
  width: max-content !important;
}

.ant-popover-message {
  width: max-content;
}

/* SELECT */
.ant-select-dropdown {
  width: 100%;
}

.ant-select-multiple.ant-select-lg {
  border-radius: 5px !important;

  .ant-select-selector {
    border-radius: 5px !important;
    padding: 0 4px !important;
  }

  .ant-select-selection-overflow {
    padding: 4.5px 6.5px;
  }

  .ant-select-selection-search {
    font-size: 12px;
    margin-top: 1px;
    margin-bottom: 1px;
    height: 22px !important;
    line-height: 20px !important;

    .ant-select-selection-search-input,
    .ant-select-selection-search-mirror {
      height: 22px !important;
      line-height: 20px !important;
      font-size: 14px;
    }
  }

  .ant-select-selection-item {
    border-radius: 8px !important;
    height: auto !important;
    font-size: 12px;
    line-height: 20px !important;
    white-space: nowrap;
    background: #f2f4f8;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .ant-select-selector::after {
    line-height: 20px;
  }
}

// DROPDOWN MENU
.ant-dropdown-menu {
  padding: 5px !important;
}

.ant-dropdown-cusstom {
  border-radius: 10px !important;
  border: 1px solid @primary-color;
}

.ant-dropdown-menu-item {
  border-radius: 8px;
}

/* TAG */
.ant-tag {
  margin: 1px 1px 1px 1px !important;
}

.ant-tag {
  &.round {
    border-radius: 8px;
  }

  &.cell-round {
    font-size: 10px;
    line-height: 15px;
    border-radius: 15px;
  }
}

/* BADGE */
.badge-without-text {
  .ant-badge-status-text {
    display: none;
  }
}

/* MODAL */
.footer-modal {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.ant-modal-confirm-content {
  white-space: pre-wrap;
}

.ant-modal-content {
  border-radius: 12px !important;
  overflow: hidden;
  padding: 8px;
}

.ant-notification {
  .ant-notification-notice-wrapper {
    background-color: @clr_ablack;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 30px 0px rgba(51, 51, 51, 1);

    .ant-notification-notice {
      background-color: @clr_ablack;
      width: 546px;
      border-radius: 10px;

      .ant-notification-notice-close {
        color: @clr_white;
        font-size: 25px;
      }

      .ant-notification-notice {
        &-icon {
          width: 20px;
          color: @clr_main;
        }

        &-message {
          font-family: "Noto serif", serif;
          font-weight: 700;
          font-size: 32px;
          color: @clr_main;
          line-height: 1.2;
        }

        &-description {
          font-size: 16px;
          font-family: "Noto sans", sans-serif;
          color: @clr_white;
          line-height: 1.2;
        }
      }
    }
  }
}

.ant-modal-footer {
  .ant-btn {
    border-radius: 8px;
  }
}

/* COLLAPSE */
.ant-collapse {
  border: none !important;
}

.ant-collapse-content {
  border-top: none !important;
}

.ant-collapse-item {
  border-bottom: none !important;
}

.ant-collapse-header {
  border-radius: 8px !important;
  margin-top: 8px;
  // background-color: #f2ebd9;
}

.collapse-parent-cusstom {
  // background-color: blue !important;
  border: 1px solid;
}

.collapse-panel-cusstom {
  background-color: white;
  border-radius: 12px !important;
}

//icon
.icon-modal {
  background-color: #ff000059;
  padding: 6px;
  border-radius: 8px;
}

.icon-modal:hover {
  background-color: #afafaf57;
  border: 1px solid rgba(128, 121, 113, 0.356);
}

/* UPLOADER */
.ant-upload.ant-upload-drag {
  border-radius: 16px !important;
  background: transparent !important;
}

.ant-upload-list {
  .ant-upload-list-item-info {
    .ant-upload-text-icon {
      display: flex;
      align-items: center;

      .anticon .anticon {
        img {
          height: 22px;
        }
      }
    }

    >span {
      display: flex;

      .ant-upload-list-item-card-actions {
        flex: none;
      }
    }
  }
}

/* ICON */
.btn-icon {
  background: white;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;

  >.anticon {
    display: inline-block;
    vertical-align: middle;
  }
}

.icon-cusstom-container {
  background-color: #f3f5f6;
  border-radius: 8px;
  padding: 6px;
}

/* DRAWER */

// .ant-drawer-content-wrapper{
//   transform:none !important;

// }

.ant-drawer-wrapper-body {
  border-left: 4px solid #75fa9b;
}

.drawer-box-shadow>.ant-drawer-content-wrapper {
  box-shadow: -19px 10px 80px 60px rgb(118 118 118 / 29%),
    -9px 0 7px 0 rgb(0 0 0 / 20%), -12px 0 48px 16px rgb(0 0 0 / 20%) !important;
}

/* CARD */

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
  background-color: white;
  border: 0px;
}

.card-detail-modal {
  min-height: 100%-3px;

  padding: 6px !important;
  border-radius: 16px !important;
  overflow-y: scroll;
}

.card-detail-modal-lv2 {
  min-height: 20vh;
  margin: 6px !important;
  padding: 6px !important;
  border-radius: 16px !important;
  overflow-y: scroll;
}

.card-in-modal {
  min-height: 50vh;
  // max-height: 77vh;
  height: 100%;
  padding: 6px !important;
  border-radius: 0px !important;
  overflow-y: scroll;

  .ant-card-body {
    height: 100% !important;
  }
}

.card-item-detail-modal:hover {
  background-color: #e2e2e2b4 !important;
}

.card-item-detail-modal {
  display: flex;
  text-align: left;
  padding: 0 2px !important;
  margin-bottom: 10px !important;
  border-radius: 12px !important;
  background-color: #f2f4f8 !important;
  flex-direction: column;
}

.card-detail {
  display: flex;
  text-align: left;
  margin-bottom: 10px !important;
  border-radius: 8px !important;
  padding: 0px 5px !important;
  overflow: hidden;

  p {
    text-wrap: wrap;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333333 !important;
  text-shadow: none !important;
}

.container-element {
  background-color: #ffffff;
  height: 100%;
}

.modul-lable-name {
  background-color: #ffffff;
  padding: 0px;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: #f2f4f8 solid 1px;
}

.ant-tabs-tabpane {
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  padding-left: 18px;
  padding-right: 18px;
  background-color: #FBF7EE;
  padding-top: 15px;
}

// .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav {
//   background-color: white ;
// }
.ant-tabs-tab-active {
  background-color: #f2f4f8 !important;
  border-radius: 16px 16px 0px 0px !important;
}

.antd-tab-cusstom {
  background-color: #f2f4f8;
  height: 100%;

  .ant-tabs-content-holder {
    overflow: auto;
  }
}

.padding-modal {
  padding: 16px;
  background-color: #f2f4f8;
}

.ant-drawer-mask {
  background: #ffffff00 !important;
}

.ant-drawer-open.ant-drawer-open-right {
  position: fixed;
}

.antd-tab-detail {
  height: auto !important;
  min-height: 400px;

  .ant-tabs-nav {
    border: 0px;

    .ant-tabs-nav-wrap {
      padding-left: 0px;
    }
  }

  .ant-tabs-tab-active {
    border-radius: 16px !important;
  }
}

/* BUTTON RADIO */
.ant-radio-button-wrapper:last-child {
  border-radius: 0 8px 8px 0 !important;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 8px 0 0 8px !important;
}

//radio button title

.ant-radio-button-wrapper {
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  font-weight: 700;
}

.pd-3 {
  padding: 20px;
}

/*TAB*/
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-more {
  background-color: white !important;
}

.custom-ant-row tbody tr:hover .link-text-table {
  color: #056ef7cb;
  text-decoration: underline;
}

.custom-ant-row tbody tr:hover .button-action-hiden-table-cell {
  display: block;
  background-color: #e0e0e075;
}

.custom-ant-row th {
  color: #056ef7cb;
}

.link-text-table {
  color: #000;
}

.button-action-hiden-table-cell {
  display: block;
  border: none;
  background-color: #ffffff00;
  border: #ffffff solid 1px;
  height: 100%;
  width: 30px;
  max-height: 35px;
  border-radius: 12%;
}

.button-action-hiden-table-cell:hover {
  background-color: #cacacac5 !important;
  border: #8585858a solid 1px;
}

.custom-card-overview {
  background-color: #ffffff !important;
  border: #a8a8a894 solid 1px;
  border-radius: 12px !important;
}

// .ant-table.ant-table-middle {
//   font-size: 12px !important;
// }
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.highlighted-row {
  color: red;
}

.not-100-percent-row {
  .has-warring {
    color: red;
  }
}

.phase-row {
  // background-color: #e4e4e459;
  // font-weight: 500;
  color: #000;
}

.warning-text {
  color: #ff0000;
}

.comm-info {
  display: flex;
  // display: -webkit-inline-box;

  overflow: hidden;

  width: 100%;

  .webkit-flow {
    display: flex;
  }

  .comm-info-scroll {
    display: -webkit-inline-box;
    overflow: auto;
    width: 100%;
  }

  .row-comm-info {
    margin-right: 30px;
  }
}

.ant-table.ant-table-middle tfoot>tr>td {
  padding: 3px !important;
}

//CARD OVERVIEW
.card-report {
  box-shadow: 0px 0px 11px 4px rgb(151 155 155 / 18%);
  display: flex;
  padding: 8px !important;
  border-radius: 12px !important;
  min-height: 100%;
}

.card-report-fit-height {
  box-shadow: 0 0 11px 4px rgb(151 155 155 / 18%);
  display: flex;
  padding: 8px !important;
  border-radius: 12px !important;
}

.card-overview-title {
  font-weight: bolder;
  font-size: 17px;
  color: #2f80ed;
}

.card-overview-second-title {
  font-weight: 700;
  font-size: 16px;
  color: #5d97e4;
}

.card-overview-content {
  font-size: medium;
}

.card-text-strong {
  font-weight: 600;
}

.card-text-right {
  display: flex !important;
  justify-content: flex-end;
}

.card-text-center {
  display: flex !important;
  justify-content: center;
}

.card-overview-cell {
  display: flex;
  padding: 0px 5px;
  // align-items: center;
}

.card-text-green {
  color: #27ae60;
}

.card-text-red {
  color: #961212;
}

.ant-switch.ant-switch-checked {
  color: @clr_main;
  background: @clr_main;

  &:hover {
    background: @clr_main !important;
  }
}

.ant-drawer .ant-drawer-content {
  background-color: #f2f4f8;
}

.custom-table-admin {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #dee3ed;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.1);
}

.tt-handle {
  .ant-tooltip-inner {
    color: #333;
    font-weight: 600;
  }
  
  .ant-tooltip-inner {
    color: #333333;
    background: #ffcb4f;
  }
}

.d-centered-form {
  background-color: @clr_white;

  .section-form {
    height: 100%;
    min-height: inherit;
    display: flex;
  }
}

.serif {
  font-family: "Noto Serif", serif;
}

// copy right flow
.wrap-copyright {
  position: absolute;
  z-index: 9;
  bottom: 0;
  right: calc(80px - 250px);
  width: 100%;
  max-width: 245px;
  background-color: #fff;
  border-radius: 10px 0px 0px;
  overflow: hidden;
  cursor: default;
  transition: .3s all ease-in-out;

  &_inner {
    position: relative;
  }

  .d-img {
    width: 100%;
    display: block;
    transition: .3s all ease-in-out;

    &_1 {
      max-width: 80px;
      opacity: 1;
    }

    &_2 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 10px 20px;
      max-width: 145px;
      min-height: 45px;
      opacity: 0;
    }
  }

  .txt-copyright {
    margin: 0;
    line-height: 1.2;
    font-size: 12px;
    padding: 0px 20px;
    font-family: "Titillium Web", sans-serif;
  }

  &:hover {
    right: 0;

    .d-img {
      &_1 {
        opacity: 0;
      }

      &_2 {
        opacity: 1;
      }
    }
  }
}

// animation
.anim-loading-btn {
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  font-family: 'Titillium Web', sans-serif;

  @anim-duration: 1.25s;
  @border-width: 2px;

  &.anim-light {

    &::after,
    span::after {
      background-color: #fff;
    }

    &::before {
      background: #fff;
    }
  }

  &.anim-yellow {

    &::after,
    span::after {
      background-color: #FFCB4F;
    }

    &::before {
      background: #FFCB4F;
    }
  }

  &::after,
  span::after {
    content: ' ';
    opacity: 0;
    position: absolute;
    background: #F99D0F;
    transition: opacity 0.7s ease;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  &::before {
    content: ' ';
    position: absolute;
    background: #F99D0F;
    bottom: 0;
    height: @border-width;
    width: 100%;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &::after {
    left: 0;
    width: 0%;
    height: @border-width;
    bottom: 0;
  }

  span::after {
    height: 0%;
    width: 2px;
    bottom: 0;
    right: 0;
  }

  &:hover {
    &::before {
      opacity: 0;
    }

    &::after,
    span::after {
      opacity: 1;
    }

    &::after {
      animation-name: loading-anim;
      animation-duration: @anim-duration;
    }
  }

  @keyframes loading-anim {
    0% {
      left: 0;
      right: auto;
      width: 0;
    }

    50% {
      width: 100%;
    }

    100% {
      left: auto;
      right: 0;
      width: 0;
    }
  }
}


// animation
.anim-loading-btn-white {
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  color: rgba(51, 51, 51, 0.8);
  font-family: 'Noto sans', sans-serif;

  @anim-duration: 1.25s;
  @border-width: 2px;

  &.anim-light {

    &::after,
    span::after {
      background-color: #fff;
    }

    &::before {
      background: #fff;
    }
  }

  &.anim-yellow {

    &::after,
    span::after {
      background-color: #FFCB4F;
    }

    &::before {
      background: #FFCB4F;
    }
  }

  &::after,
  span::after {
    content: ' ';
    opacity: 0;
    position: absolute;
    background: #ffffff;
    transition: opacity 0.7s ease;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  &::before {
    content: ' ';
    position: absolute;
    background: #ffffff;
    bottom: 0;
    height: @border-width;
    width: 100%;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &::after {
    left: 0;
    width: 0%;
    height: @border-width;
    bottom: 0;
  }

  span::after {
    height: 0%;
    width: 2px;
    bottom: 0;
    right: 0;
  }

  &:hover {
    &::before {
      opacity: 0;
    }

    &::after,
    span::after {
      opacity: 1;
    }

    &::after {
      animation-name: loading-anim;
      animation-duration: @anim-duration;
    }
  }

  @keyframes loading-anim {
    0% {
      left: 0;
      right: auto;
      width: 0;
    }

    50% {
      width: 100%;
    }

    100% {
      left: auto;
      right: 0;
      width: 0;
    }
  }
}


// animation
.anim-loading-btn-black {
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  color: rgba(51, 51, 51, 0.8);
  font-family: 'Noto sans', sans-serif;

  @anim-duration: 1.25s;
  @border-width: 2px;

  &.anim-light {

    &::after,
    span::after {
      background-color: #333333;
    }

    &::before {
      background: #333333;
    }
  }

  &.anim-yellow {

    &::after,
    span::after {
      background-color: #FFCB4F;
    }

    &::before {
      background: #FFCB4F;
    }
  }

  &::after,
  span::after {
    content: ' ';
    opacity: 0;
    position: absolute;
    background: #333333;
    transition: opacity 0.7s ease;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  &::before {
    content: ' ';
    position: absolute;
    background: #333333;
    bottom: 0;
    height: @border-width;
    width: 100%;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &::after {
    left: 0;
    width: 0%;
    height: @border-width;
    bottom: 0;
  }

  span::after {
    height: 0%;
    width: 2px;
    bottom: 0;
    right: 0;
  }

  &:hover {
    &::before {
      opacity: 0;
    }

    &::after,
    span::after {
      opacity: 1;
    }

    &::after {
      animation-name: loading-anim;
      animation-duration: @anim-duration;
    }
  }

  @keyframes loading-anim {
    0% {
      left: 0;
      right: auto;
      width: 0;
    }

    50% {
      width: 100%;
    }

    100% {
      left: auto;
      right: 0;
      width: 0;
    }
  }
}


/* MODAL */
.md-share {
  &.md-qr-code {
    .ant-modal-content {
      max-width: 670px;
    }

    .md-share-content {
      margin-bottom: 20px;
    }
  }

  .ant-modal-content {
    background-color: @clr_ablack;
    max-width: 450px;

    .ant-modal-close {
      color: @clr_white;

      &:hover {
        color: @clr_white;
        background-color: transparent;
      }
    }
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: @clr_white;
    padding-bottom: 30px;

    .ico {
      max-width: 45px;
      margin-top: 27px;
      margin-bottom: 10px;
      color: #F99D0F;
    }

    .txt-share {
      color: @clr_white !important;
      font-weight: 700;
      margin: 0;
    }
  }

  &-selected {
    margin-bottom: 50px;

    .text {
      color: @clr_white;
      margin: 0;
    }

    .ico {
      max-width: 35px;
      height: 35px;
      color: #F99D0F;
      margin-bottom: 10px;
    }

    .wrap-select {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 25px;
        padding-right: 25px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
  }

  &-action {
    text-align: -webkit-center;

    .btn-confirm {
      max-width: 104px;
      margin-bottom: 50px;
    }

    .btn-download {
      max-width: 209px;
    }
  }

  &-qr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .qr-code {
      background-color: @clr_white;
      // padding: 12px;
    }
  }

  .md-share-logo {
    display: none;
  }
}

.md-reset {
  margin: 0 !important;
  padding: 0 !important;
}

@media screen and (max-width: 767px) {
  .ant-modal-root .ant-modal.md-share {
    width: 100vw !important;
    max-width: 100vw;
    height: calc(100vh);
    margin: 0;
    top: 0;
    border-radius: unset;

    .md-share-content {
      color: @clr_main;

      .txt-share {
        color: inherit;
      }
    }

    .mob-action {
      .action-close {
        width: 90px;
        height: 90px;
        background-color: rgba(108, 117, 125, 1);
        border-radius: 999px;
        margin: auto;
        position: relative;
      }

      .ico-close,
      .ico-chevron-left {
        color: rgba(51, 51, 51, 1);
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 23px;
        transform: translate(-50%, -50%);
      }

      .ico-chevron-left {
        max-width: 32px;
      }
    }

    .ant-modal-content {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      border-radius: 0 !important;
      background-color: rgba(51, 51, 51, 1);

      .ant-modal-body {
        width: 100%;
        display: flex;
        height: inherit;
        flex-direction: column;
        justify-content: space-between;

        .md-share-action {
          width: 100%;
        }
      }

      .md-share-logo {
        text-align: center;
        display: block;
        margin-top: 30px;
        margin-bottom: 10px;
      }
    }
  }
}

.custom-switch {
  @handle-size: 21px;
  @handle-padding: 2.5px;
  font-family: 'Titillium Web';

  height: 26px;
  background: #BBBBBB;

  .ant-switch-handle {
    top: @handle-padding;
    width: @handle-size;
    height: @handle-size;

    &::before {
      background: #6C757D;
      border-radius: 20px;
    }
  }

  .ant-switch-inner {
    padding-top: 2px;
    padding-inline-start: 28px;
    padding-inline-end: 8px;

    .ant-switch-inner-checked,
    .ant-switch-inner-unchecked {
      font-weight: 600;
      font-family: 'Titillium Web';
    }
  }


  &.ant-switch-checked {
    color: #000000;
    background: #000000 !important;

    .ant-switch-handle {
      inset-inline-start: calc(100% - (@handle-size + @handle-padding));

      &::before {
        background: #FFCB4F;
      }
    }

    .ant-switch-inner {
      padding-inline-start: 10px;
      padding-inline-end: 26px;
    }
  }
}

.font-text {
  font-family: "Noto-sans", sans-serif !important;
}

.font-title {
  font-family: "Noto-serif", serif !important;
}

.check-box-custom {
  .chk-save {
    font-size: 16px !important;
    font-weight: 400;
    color: #333 !important;

    &.white {
      color: #fff !important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #FFCB4F !important;
    border-color: #FFCB4F !important;
  }
}

// modal error
.ant-modal-confirm-error {
  .ant-modal-content {
    background-color: #333;
    padding: 0;

    .ant-modal-confirm-body-has-title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      color: #fff;
      padding-bottom: 20px;
      border-style: solid;
      border-width: 0;
      border-bottom-width: 5px;
      border-image-slice: 1;
      border-image-source: linear-gradient(266.04deg, #9A1E85 42.38%, #F99D0F 109.66%, #FFCB4F 139.1%);

      .ant-modal-confirm-paragraph {
        flex: unset;

        .ant-modal-confirm-title {
          color: #fff;
        }

        .ant-modal-confirm-content {
          color: #fff;
          padding: 0 10px;
        }
      }

    }

    .ant-modal-confirm-btns {
      text-align: center;

      button {
        width: 100%;
        max-width: 200px;
        height: 60px;
        font-weight: 700;
        color: #fff;
        text-transform: capitalize;
      }
    }
  }
}

@import "utils/components.less";

@import "pages/authen.less";
@import "pages/loading-screen.less";
@white-color: #ffffff;@primary-color: #75FA9B;@text-color: #333;@color-error: rgba(231, 105, 95, 1);@color-success: rgba(82, 203, 103, 1);@place-holder-text: rgba(29, 68, 73, 0.32);@border-color: #cccccc;@border-primary-color: rgba(110, 186, 196, 0.4);@layout-bg-color: #F7F7F7;@padding-md: 16px;@control-padding-horizontal: @padding-md;@height-lg: 44px;@error-color: #f5222d;@screen-sm: 600px;@screen-md-min: 600px;@text-color-secondary: #fff;@primary-6: black;