.authen-page {
  color: #fff;
  font-size: 16px;
  font-family: "Titillium Web", serif;

  .section {
    &-form {
      top: 50%;
      left: 0;
      height: 80%;
      z-index: 1;
      padding: 15px;
      position: absolute;
      transform: translateY(-50%);
      box-shadow: 0px 0px 50px 0px rgba(30, 30, 30, 0.5);
      overflow-y: scroll;
      border-radius: 20px;
      background-color: rgba(51, 51, 51, 0.95);

      display: grid;
      // align-items: center;
      // flex-direction: column;
      // justify-content: center;
      // gap: 18px;

      &::-webkit-scrollbar {
        width: 0;
      }

      @media screen and (min-width: 576px) {
        left: 50%;
        min-width: 400px;
        transform: translate(-50%, -50%);
      }

      @media screen and (min-width: 768px) {
        left: 60px;
        transform: translateY(-50%);
      }

      .form-header {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;

        img {
          width: 100%;
          max-width: 175px;
          margin: auto;
        }
      }

      .form-content {
        max-width: 375px;
        margin: auto;

        .wrap-logo {
          margin: 50px 0;
          padding: 20px 0;
          text-align: center;

          .lg-image {
            width: 100%;
            max-width: 175px;
            margin: auto;

            &-admin {
              width: 100%;
              max-width: 250px;
              margin: auto;
            }
          }
        }

        &_inner {
          padding: 0 25px;
          margin: 0 auto;
        }

        .form-title {
          font-size: 16px;
          margin-bottom: 35px;
          font-family: "Titillium Web", serif;
        }
      }

      .form-footer {
        padding: 25px 0;
        margin: 35px 0;

        img {
          width: 100%;
          max-width: 220px;
          margin: auto;
        }
      }
    }
  }

  .ant-input.ant-input-lg {
    background: rgba(255, 255, 255, 0.05) !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    -webkit-box-shadow: unset !important;
    color: #fff;
    border-color: unset !important;
    border: unset !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #FFCB4F !important;
    padding: 12.5px 12px !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px rgba(255, 255, 255, 0.05);
    }
  }

  .anticon.ant-input-password-icon {
    color: #FFCB4F;
  }

  .ant-input-affix-wrapper {
    border: unset !important;
    border-radius: 0;
    border-bottom: 1px solid #FFCB4F !important;
    border-radius: 0px !important;
    background-color: unset !important;
    padding: 0 !important;

    .ant-input.ant-input-lg {
      border: unset !important;
    }

    .ant-input-suffix {
      background-color: rgba(255, 255, 255, 0.05) !important;
      padding-right: 12px;
      font-size: 24px;
      margin-inline-start: 0px;
    }
  }

  .lbl-subtitle {
    font-family: "Titillium Web", serif;
    font-size: 12px;
    margin: 0 0 8px;
    color: #fff;
  }

  .cs-validate-pass {
    .checklist-valid {
      border-color: #FFCB4F;

      .checklist-title {
        color: #fff !important;
      }

      .checklist-item {
        &_inner {
          color: #fff;
        }
      }
    }
  }

  .button-action {
    height: 60px;
    width: 100%;
    color: #fff;
    font-size: 18px;
    background: linear-gradient(266.04deg, #9A1E85 42.38%, #F99D0F 109.66%, #FFCB4F 139.1%);
    margin-top: 45px;
    border-right: 0;
    border-radius: 0;
    font-family: "Titillium Web", serif;

    &:hover {
      color: #fff !important;
      background: linear-gradient(266.04deg, #9A1E85 42.38%, #F99D0F 109.66%, #FFCB4F 139.1%) !important;
    }
  }
}

// @media screen and (min-width: 768px) {}
// @media screen and (min-width: 992px) {}
// @media screen and (min-width: 1200px) {}