.loading-screen {
  position: relative;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  background-color: #333;

  &_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 18px;

    img {
      margin-bottom: 40px;
      max-width: 62px;
    }
  }

  .loading-process {
    width: inherit;
    position: absolute;
    bottom: 0;
    height: 5%;
    background: linear-gradient(252.56deg, rgba(154, 30, 133, 0.97) 19.32%, rgba(249, 157, 15, 0.97) 78.9%, rgba(255, 203, 79, 0.97) 104.97%);

    // anim
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  .wrap-text {
    color: #fff;
  }
}


@keyframes anim_progress {
  0% {
    height: 5%;
  }

  100% {
    height: 100%;
  }
}

@keyframes anim_end_progress {
  0% {
    height: 50%;
  }

  100% {
    height: 100%;
  }
}