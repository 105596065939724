.modal-valid-login {
    background-color: #333333;
    width: 680px;
    color: #FFFFFF;
    border-radius: 20px;
    padding: 20px 0;

    .md-header {
        font-size: 24px;
        line-height: 1.2;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
        text-align: center;
        border-style: solid;
        border-width: 0;
        border-bottom-width: 5px;
        border-image-slice: 1;
        border-image-source: linear-gradient(266.04deg, #9A1E85 42.38%, #F99D0F 109.66%, #FFCB4F 139.1%);
    }

    .md-content {
        padding: 32px;
        font-size: 18px;
        line-height: 24px;
    }

    .md-footer {
        text-align: center;
    }


    .button-action {
        height: 60px;
        width: 100%;
        max-width: 200px;
        border: unset;
        color: #fff !important;
        font-size: 18px;
        background: linear-gradient(266.04deg, #9A1E85 42.38%, #F99D0F 109.66%, #FFCB4F 139.1%);
        border-right: 0;
        border-radius: 0;
        font-family: "Titillium Web", serif;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        user-select: none;

        &:hover,
        &:active,
        &:focus {
            color: #fff !important;
            opacity: 0.75;
            background: linear-gradient(266.04deg, #9A1E85 42.38%, #F99D0F 109.66%, #FFCB4F 139.1%) !important;
        }

    }
}
@white-color: #ffffff;@primary-color: #75FA9B;@text-color: #333;@color-error: rgba(231, 105, 95, 1);@color-success: rgba(82, 203, 103, 1);@place-holder-text: rgba(29, 68, 73, 0.32);@border-color: #cccccc;@border-primary-color: rgba(110, 186, 196, 0.4);@layout-bg-color: #F7F7F7;@padding-md: 16px;@control-padding-horizontal: @padding-md;@height-lg: 44px;@error-color: #f5222d;@screen-sm: 600px;@screen-md-min: 600px;@text-color-secondary: #fff;@primary-6: black;