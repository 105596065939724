.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  &.d-btn-primary {
    border-color: unset;
    background: @clr_grad_button;
  }

  &.d-btn-secondary {
    border-color: unset;
    background: #333;
    background-color: #333;
  }
}

.d-btn {
  width: 100%;
  min-height: 60px;
  font-size: 18px;
  font-family: 'Titillium Web', serif;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  font-weight: 700;

  &-primary {
    background: @clr_grad_button;
    background-color: unset;
    border: unset;
    font-family: 'Titillium Web', serif;
    color: #fff;

    &:hover {
      color: #fff !important;
      opacity: .8;
    }
  }

  &-secondary {
    background-color: @clr_ablack;
    border: unset;
    border: 1px solid @clr_ablack;
    font-family: 'Titillium Web', serif;
    color: #fff;

    &:hover {
      color: #fff !important;
      background-color: rgba(51, 51, 51, .9);
    }

    .ico {
      color: @clr_main;
    }
  }

  .ico {
    width: 100%;
    max-width: 20px;
  }
}

.under {
  text-decoration: underline;
}