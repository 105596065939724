@import (once) url("utils/variable");

// bootstrap
.text-muted {
  color: fade(@text-color, 60%) !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}
.small {
  font-size: 12px;
}

.large {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 5px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.mb-4 {
  margin-bottom: 20px !important;
}
.mb-5 {
  margin-bottom: 25px !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mt-4 {
  margin-top: 20px !important;
}
.mt-5 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 150px !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mr-4 {
  margin-right: 20px !important;
}
.mr-5 {
  margin-right: 40px !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.mx-2 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-3 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.mx-4 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mx-3 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.my-2 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-3 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
/* Padding */
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 5px !important;
}
.p-2 {
  padding: 10px !important;
}
.p-3 {
  padding: 15px !important;
}
.p-4 {
  padding: 20px !important;
}
.p-5 {
  padding: 30px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 5px !important;
}
.pb-2 {
  padding-bottom: 10px !important;
}
.pb-3 {
  padding-bottom: 15px !important;
}
.pb-4 {
  padding-bottom: 20px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pt-2 {
  padding-top: 10px !important;
}
.pt-3 {
  padding-top: 15px !important;
}
.pt-4 {
  padding-top: 20px !important;
}
.pt-5 {
  padding-top: 25px !important;
}
.pt-6 {
  padding-top: 30px !important;
}
.pt-7 {
  padding-top: 40px !important;
}
.pt-8 {
  padding-top: 50px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 5px !important;
}
.pl-2 {
  padding-left: 10px !important;
}
.pl-3 {
  padding-left: 15px !important;
}
.pr-0 {
  padding-right: 0 !important;
}

.px-2pt {
  padding-right: 2% !important;
  padding-left: 2% !important;
}

.pr-1 {
  padding-right: 5px !important;
}
.pr-2 {
  padding-right: 10px !important;
}
.pr-3 {
  padding-right: 15px !important;
}
.pr-4 {
  padding-right: 20px !important;
}
.pr-5 {
  padding-right: 30px !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.px-2 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-3 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.px-4 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

/* Display */
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.gap-10 {
  gap: 1em;
}

/* Text color */
.text-primary {
  color: @primary-color !important;
}
.text-success {
  color: #28a745 !important;
}
.text-danger {
  color: #ff5f61 !important;
}
.text-dart {
  color: #c4c4c4 !important;
}
/* Text align */
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}

/* Grid */
.row {
  display: flex !important;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  .col,
  .col-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-fit {
  height: fit-content !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}
.border {
  border: 1px solid #cccccc;
}
.rounded {
  border-radius: 8px !important;
}

.rounded-top {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-right {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-left {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}
.bg-primary {
  background-color: @primary-color !important;
}
.bg-warning {
  background-color: #fff1cc !important;
}
.hover-pointer {
  cursor: pointer;
}

@white-color: #ffffff;@primary-color: #75FA9B;@text-color: #333;@color-error: rgba(231, 105, 95, 1);@color-success: rgba(82, 203, 103, 1);@place-holder-text: rgba(29, 68, 73, 0.32);@border-color: #cccccc;@border-primary-color: rgba(110, 186, 196, 0.4);@layout-bg-color: #F7F7F7;@padding-md: 16px;@control-padding-horizontal: @padding-md;@height-lg: 44px;@error-color: #f5222d;@screen-sm: 600px;@screen-md-min: 600px;@text-color-secondary: #fff;@primary-6: black;